import React, { useState, useEffect, Suspense, useContext } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
    useLocation,
    Link,
    Navigate,
    useParams
} from "react-router-dom";
import { AppContext } from '../App';
import { TruncateSimple } from '../components/_common/Utils';

import Logo from '../components/_common/Logo';
import { MenuIcon, SearchIcon, UsersIcon, MapMarker2Icon, MessageCircleIcon, TwitterIcon, AtSignIcon, ChevronLeftIcon, LockIcon, UnlockIcon, Maximize2Icon, XCircleIcon } from '../components/_common/Icons'

import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { ethers } from "ethers";


//https://pigeon-maps.js.org/docs/map
import { Map, Marker, ZoomControl, Draggable } from "pigeon-maps"
import { osm, stamenToner, stamenTerrain } from 'pigeon-maps/providers'

import QrReader from 'react-qr-reader'

import { Sanctum, useSanctum } from '../hooks/useSanctum';

const axios = require('axios').default;




function Menu(props) {
    const appContext = React.useContext(AppContext);
    const { authenticated, user, checkAuthentication } = useSanctum();

    const navigate = useNavigate();
    const location = useLocation();



    ///
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [center, setCenter] = useState([50.879, 4.6997])
    const [zoom, setZoom] = useState(6);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [id, setId] = useState();
    const [contract, setContract] = useState();
    const [result, setResult] = useState('');
    const [scantype, setScantype] = useState([]); //Token //TokenAR //User //Claim //Payment S/R

    const [activeSignature, setActiveSignature] = useState(false);

    const [addressUser, setAddressUser] = useState('');


    //const history = useHistory();

    const [nft, setNft] = useState([]);
    const [tick, setTick] = useState(0);



    //Init////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        checkAuthentication();
        apiGetUserFromAddress();
        
        return () => {
            //cleanup
        }
    }, [])

    useEffect(() => {
        let str = appContext.sessionMessage;
        const expiration = (parseInt(str.substr(str.indexOf('.') + 1)) / 1000).toFixed(0);
        const now = (parseInt(Date.now()) / 1000).toFixed(0);
        console.log('now: ', now + '| expires: ' + expiration);
        if (appContext.sessionSignedMessage == '' || appContext.sessionMessage == '') {
            setActiveSignature(false);
        } else {
            if (expiration >= now) {
                //SUCCESS || NOT EXPIRED
                setActiveSignature(true);
            } else {
                setActiveSignature(false);
            }
        }
    }, [location, tick])


    //API GetUserFromAddress ////////////////////////////////////////////////////
    async function apiGetUserFromAddress() {

        axios.defaults.headers.post['Accept'] = 'application/json';
        axios.defaults.withCredentials = true;
        //axios.get('http://localhost:8000/sanctum/csrf-cookie').then(response => {
        //    console.log('CSRF Res: ', response);
        axios.get(appContext.apiRoot + 'user/' + props.account).then(res => {
            console.log('API ADDRESS: ', res);
            setAddressUser(res.data);
            //setEditInfo(res.data);
            //
            if (res.data.status === 200) {
                //setUser(res.data[0])
                //console.log(res.data[0]);
            } else if (res.data.status === 401) {
                //
            } else {
                //
            }
            //
        }).catch(res => {
            console.log('Catch Res: ', res);
        })
    }



    //Functions////////////////////////////////////////////////////////////////////////////////////////
    function logout() {
        axios.defaults.headers.post['Accept'] = 'application/json';
        axios.defaults.withCredentials = true;
        axios({
            method: "post",
            url: appContext.apiRoot + 'logout',//props.appContext.apiGateway + props.modelName,
            //data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                handleClose();
                checkAuthentication();
                //Navigate("/", { replace: true });
                window.location.replace('/');

                /*handle logout on frontend
                console.log(response);
                if (response.auth = true) {
                    //setAuthorized(true);
                }
                //return $nonce;
                */
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            })

    }


    //Scanner
    function parseURL(data) {
        let payload = data.slice(21);
        var mySubString = payload.split('/');
        setId(mySubString[1]);
        setContract(mySubString[0]);
        console.log(mySubString);
        return mySubString
    }

    function handleScan(data) {
        if (data) {
            setResult(data)
            //Parse QRCode URL
            //Call Api to Display button.
            //let id = data.slice(64);
            //let contract = data.slice(21,63);
            let urlParts = parseURL(data);


            //loadApi(urlParts[0], urlParts[1]);

            //OR REDIRECT
            //window.location.href = data;
            //navigate.push("/" + data.slice(21));

        }
    }

    function goLink() {
        //navigate("/" + data.slice(21));
        handleClose3();
        navigate(result);
    }

    function handleError(err) {
        console.error(err)
    }

    //Maps
    function handleMarkerClick(evt) {
        console.log("Marker Click data: ", evt);
        //Nvigate('/721tokens/' + evt.payload.tokenId);
        window.location.href = '/721tokens/' + evt.payload.tokenId;
        //console.log("Marker data: ", coordinates);
    }

    function handleMapClick(evt) {
        console.log("Map Click data: ", evt);
        //setAnchor(evt.latLng)
        //console.log("Marker data: ", coordinates);
    }

    const signMessage = async (e) => {

        e.preventDefault();

        const expirationTime = 300000; //In milliseconds 5min*60 = 300 * 1000 = 300000
        //Check if there is a valid appContext message so user doesnt have to sign so often, great for mobile.
        if (appContext.sessionSignedMessage == '' || appContext.sessionMessage == '') {
            //NEW SESSION AFTER REFRESH
            console.log("CREATE")
            //SET Expirations
            const ts = parseInt(parseInt(Date.now()) + expirationTime);//add 6min sec in millseconds
            //console.log('Now: ', Date.now());
            //console.log('Expires: ', Date.now() + expirationTime);


            //MAKE message
            var text = 'VizCache Public Request: ' + props.account + '.' + ts + '_' + 'public';
            //console.log('msg', text);
            //setMessageText(text);
            //setMessage(text);

            //REQUEST Signing
            let signature = await props.provider.send(
                'personal_sign',
                [ethers.utils.hexlify(ethers.utils.toUtf8Bytes(text)), props.account.toLowerCase()]
            );

            //SET the returned Message
            //setSignedMsg(signature);
            //console.log('signature', signature);

            //SET App Context
            //Set the AppContext Signed message with a 5min expiration or a with refresh. 
            appContext.setSessionMessage(text, signature);
            setTick(tick + 1);
            //Change UX
            //setSigned(true);


        } else {
            //GET existing message with expiration.
            let str = appContext.sessionMessage;
            const expiration = (parseInt(str.substr(str.indexOf('.') + 1)) / 1000).toFixed(0);
            const now = (parseInt(Date.now()) / 1000).toFixed(0);
            console.log('now: ', now + '| expires: ' + expiration);

            if (expiration >= now) {
                //SUCCESS || NOT EXPIRED
                //use App Context signed message
                //setSignedMsg(appContext.sessionSignedMessage);
                //setMessage(appContext.sessionMessage);
                //setSigned(true);
                setTick(tick + 1);
            } else {
                console.log("RENEW")
                //FAILS Sign RENEW Message
                //SET Expirations
                const ts = parseInt(parseInt(Date.now()) + expirationTime);//add 6min sec in millseconds
                //console.log('Now: ', Date.now());
                //console.log('Expires: ', Date.now() + expirationTime);

                //MAKE message
                var text = 'VizCache Public Request: ' + props.account + '.' + ts + '_' + 'public';
                //console.log('msg', text);
                //setMessageText(text);
                //setMessage(text);

                //REQUEST Signing
                let signature = await props.provider.send(
                    'personal_sign',
                    [ethers.utils.hexlify(ethers.utils.toUtf8Bytes(text)), props.account.toLowerCase()]
                );

                //SET the returned Message
                //setSignedMsg(signature);
                //console.log('signature', signature);

                //SET App Context
                //Set the AppContext Signed message with a 5min expiration or a with refresh. 
                appContext.setSessionMessage(text, signature);
                setTick(tick + 1);

                //Change UX
                //setSigned(true);
            }

        }

    }


    return (
        <>
            <Navbar variant="dark" sticky="top" style={{ zIndex: '8999', backgroundColor: '#111' }} >
                <Container fluid>
                    <Navbar.Brand as={Link} to="/">
                        <Logo />{' '}
                    </Navbar.Brand>
                    <Nav className="container-fluid" >
                        <NavDropdown hidden title="More..." id="collasible-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/admin/chains">Admin Chains</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item as={Link} to="/721Tokens">721Tokens (Owners/Renters)</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <Nav>
                        <Button hidden className='me-1' variant="dark" title='Scan' as={Link} to={'/u/' + props.account + '/messages'} >
                            <MessageCircleIcon />
                        </Button>
                        <div className='mx-2 my-3' variant="dark" title='Scan' onClick={handleShow3} style={{}}  >
                            <Maximize2Icon className='m-4' />
                        </div>
                        <Button hidden className='me-1' variant="dark" title='CacheMap' onClick={handleShow2}>
                            <MapMarker2Icon />
                        </Button>
                        {
                            authenticated ? (<>
                                <div variant="dark" className='m-3' style={{}} onClick={handleShow}>
                                    <MenuIcon />
                                </div>
                                <div hidden className='btn-4 p-0 m-3  shadow' style={{ height: '36px', textAlign: 'center', borderRadius: '5px', aspectRatio: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <div
                                        onClick={handleShow}
                                        className='shadow menuThumbnail p-0 '
                                        style={{
                                            opacity: 0.6,
                                            height: '34px',
                                            textAlign: 'center',
                                            borderRadius: '5px',
                                            backgroundColor: '#000',
                                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(${user && appContext.storageRoot + user.thumbnail})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            aspectRatio: '1',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                    </div>
                                </div>
                            </>
                            ) : (
                                <>
                                    <Button hidden variant="success" as={Link} to='/login'>
                                        Login
                                    </Button>
                                    <div variant="dark" className='m-3' style={{}} onClick={handleShow}>
                                        <MenuIcon />
                                    </div>
                                </>
                            )
                        }
                    </Nav>
                </Container>
            </Navbar>



            <Offcanvas style={{ zIndex: 9999, backgroundColor: '#111' }} className='bg-dark text-light' show={show} placement='end' onHide={handleClose}>
                <div hidden style={{ position: 'absolute', left: '10px', top: '10px', zIndex: '1000', cursor: 'pointer' }} onClick={handleClose}>
                    <XCircleIcon></XCircleIcon>
                </div>
                <div>
                    {
                        props.account !== '' ? (
                            <div width="100%">
                                <div style={{ backgroundColor: '#111', height: '75px', width: '100%', flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} variant="dark" >
                                    <div onClick={handleClose} style={{ height: '75px', width: '75px', aspectRatio: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <ChevronLeftIcon color='#ddd' size={42} />
                                    </div>
                                    <Link hidden className='text-light' as={Link} to={'/u/' + props.account}><strong>{props.account && TruncateSimple(props.account, 18)}</strong></Link>
                                    <span style={{ fontSize: '10px' }} class="text-success logged-in">●</span>
                                    <span className="text-light" style={{ fontSize: '20px' }}>{TruncateSimple(props.account, 24)}</span>
                                    <div className='btn-4 p-0 m-3  shadow' style={{ height: '56px', textAlign: 'center', borderRadius: '100%', aspectRatio: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                        <Link
                                            to={'/u/' + props.account}
                                            as={Link}
                                            onClick={handleClose}
                                            className='shadow menuThumbnail p-0 '
                                            style={{
                                                opacity: 0.86,
                                                height: '54px',
                                                textAlign: 'center',
                                                borderRadius: '100%',
                                                backgroundColor: '#000',
                                                background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(${user ? (appContext.storageRoot + user.thumbnail) : (appContext.storageRoot + addressUser.thumbnail)})`,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                aspectRatio: '1',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                        </Link>
                                    </div>

                                    <a hidden className='text-danger' style={{ cursor: 'pointer' }} onClick={() => { props.unloadWallet(); handleClose(); }}>Disconnect</a>
                                </div>
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div onClick={handleClose} style={{ height: '75px', width: '75px', aspectRatio: '1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <ChevronLeftIcon color='#333' size={42} />
                                </div>
                                <div style={{ height: '75px', flexGrow: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} className='bg-success text-light' variant="success" onClick={() => { props.loadWallet(); }}>
                                    {props.account == '' ? 'Connect Wallet' : props.account && TruncateSimple(props.account, 36)}
                                </div>

                            </div>
                        )
                    }
                    <span hidden style={{ fontSize: '10px' }}>{props.account == '' ? 'Guest' : props.account && TruncateSimple(props.account, 64)}</span>
                </div>
                <Offcanvas.Body className='p-0 ' style={{ backgroundColor: '#111', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ flexGrow: '1', width: '100%' }}>
                        <ListGroup variant='flush' className='bg-dark text-light'>
                            {props.account !== '' ? (
                                authenticated ? (
                                    <>
                                        <ListGroup.Item hidden className='bg-dark text-light m-0 p-0' onClick={handleClose} as={Link} to={'/u/' + props.account}>
                                            <Button style={{ height: '75px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} variant="dark">
                                                <h6 className='m-2'>My Address</h6>
                                                <span className="text-muted" style={{ fontSize: '10px' }}>{props.account}</span>
                                            </Button>
                                        </ListGroup.Item>
                                        <Link hidden onClick={handleClose} as={Link} to={'/u/' + props.account + '/dashboard'} title='Private Dashboard' style={{ color: '#fff', textDecoration: 'none', height: '75px', width: '100%', flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(/banner.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }} variant="dark" >
                                            <UnlockIcon></UnlockIcon><span className='ms-2'>{user && user.name} | {user && user.role}</span>
                                        </Link>
                                        <Link onClick={handleClose} as={Link} to={'/u/' + props.account + '/dashboard'} title='Private Dashboard' className='btn-4 m-0 p-0' style={{ color: '#fff', textDecoration: 'none', height: '76px', width: '100%', flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="dark" >
                                            <div style={{ color: '#fff', textDecoration: 'none', height: '74px', width: '100%', flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#000000cc' }}>
                                                <UnlockIcon></UnlockIcon><span className='ms-2'>{user && user.name}</span>
                                            </div>
                                        </Link>

                                        <Row md={2} className='m-0 g-0'>
                                            <Col className='m-0'>
                                                <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/members'}
                                                    style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                >
                                                    <h6 hidden className='m-2'>Members</h6>
                                                    <UsersIcon />
                                                </ListGroup.Item>
                                            </Col>
                                            <Col className='m-0'>
                                                <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + '/messages'}
                                                    style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                >
                                                    <h6 hidden className='m-2'>My Messages</h6>
                                                    <MessageCircleIcon />
                                                </ListGroup.Item>
                                            </Col>
                                        </Row>

                                        <ListGroup.Item hidden className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + '/dashboard'}>
                                            <h6 className='m-2'>Private Dashboard </h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item hidden className='menuListItem' onClick={handleClose} as={Link} to={'/claim-membership'}
                                            style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <h6 className='m-2'>Claim Membership</h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/collections'}
                                            style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <h6 className='m-2'>Collections</h6>
                                        </ListGroup.Item>

                                        <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/contracts'}
                                            style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <h6 className='m-2'>Contracts</h6>
                                        </ListGroup.Item>

                                        <ListGroup.Item hidden className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + '/simplemint'}
                                            style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <h6 className='m-2'>Simple Mint</h6>
                                        </ListGroup.Item>
                                    </>
                                ) : (
                                    <>
                                        <ListGroup.Item hidden className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account}>
                                            <h6 className='m-2'>My Address</h6>
                                            <span className="text-muted" style={{ fontSize: '10px' }}>{props.account}</span>
                                        </ListGroup.Item>
                                        <Link hidden style={{ width: '100%', margin: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} class="btn btn-13 glow-on-hover2" onClick={handleClose} as={Link} to='/login'>
                                            <LockIcon></LockIcon> <span className='ms-2' style={{ color: '#fff', fontWeight: '800' }}>NFT Login</span>
                                        </Link>
                                        <Link style={{ width: '100%', height: '75px', margin: '0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} class="btn btn-13" onClick={handleClose} as={Link} to='/login'>
                                            <LockIcon></LockIcon> <span className='ms-2' style={{ color: '#fff', fontWeight: '800' }}>NFT Login</span>
                                        </Link>
                                        <Row md={2} className='m-0 g-0'>
                                            <Col className='m-0'>
                                                <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/members'}
                                                    style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                >
                                                    <h6 hidden className='m-2'>Members</h6>
                                                    <UsersIcon />
                                                </ListGroup.Item>
                                            </Col>
                                            <Col className='m-0'>
                                                <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + '/messages'}
                                                    style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                >
                                                    <h6 hidden className='m-2'>My Messages</h6>
                                                    <MessageCircleIcon />
                                                </ListGroup.Item>
                                            </Col>
                                        </Row>
                                        <ListGroup.Item hidden className='menuListItem' onClick={handleClose} as={Link} to={'/claim-membership'}
                                            style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <h6 className='m-2'>Claim Membership</h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/collections'}
                                            style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <h6 className='m-2'>Collections</h6>
                                        </ListGroup.Item>

                                        <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/contracts'}
                                            style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <h6 className='m-2'>Contracts</h6>
                                        </ListGroup.Item>



                                        {/* 
                                    <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/contracts'}
                                        style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <h6 className='m-2'>Contracts</h6>
                                    </ListGroup.Item>
                                    CLOSED IN ALPHA
                                    <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/posts'}
                                        style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <h6 className='m-2'>Posts</h6>
                                    </ListGroup.Item>

                                    */}
                                    </>

                                )
                            ) : (
                                <></>
                            )
                            }



                            {
                                authenticated && (user.role == 'site-manager' || user.role == 'admin') ? (
                                    <>
                                        <ListGroup.Item hidden action className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + "/posts"}
                                            style={{ height: '74px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        >
                                            <h6 className='m-2'>My Posts</h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item hidden action className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + "/draft"}>
                                            <h6 className='m-2'>Pre-Mint</h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item hidden action className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + "/posts/1/files"}>
                                            <h6 className='m-2'>Files</h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item action hidden className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + "/posts/1/manifest"}>
                                            <h6 className='m-2'>Manifest</h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item action hidden className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + "/posts/1/cloner"}>
                                            <h6 className='m-2'>Cloner</h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item action hidden className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + "/posts/1/mint"}>
                                            <h6 className='m-2'>Mint</h6>
                                        </ListGroup.Item>


                                    </>

                                ) : (
                                    <>

                                    </>
                                )

                            }


                        </ListGroup>


                        <Accordion id='accordionMenu' flush className='bg-dark' style={{ border: 'none', borderRadius: '0px', backgroundColor: '#333' }} defaultActiveKey="0">
                            {
                                authenticated && user.role == 'site-manager' ? (
                                    <>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                <div>
                                                    <h5>ERC721 - Static</h5>
                                                    <p className='text-light m-0' style={{ fontSize: '12px' }}>Manage and Mint NFT Tokens For Testing</p>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className='m-0 p-0'>
                                                <ListGroup variant='flush' defaultActiveKey="#link1">
                                                    <ListGroup.Item action className='menuListItem' onClick={handleClose} as={Link} to="/721Minter">
                                                        <h6 className='m-2'>ERC721 Minter</h6>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item action className='menuListItem' onClick={handleClose} as={Link} to="/721Tokens">
                                                        <h6 className='m-2'>ERC721 Tokens</h6>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item action className='menuListItem' onClick={handleClose} as={Link} to="/721Trades">
                                                        <h6 className='m-2'>ERC721 Trades</h6>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item action className='menuListItem' onClick={handleClose} as={Link} to="/721Auctions">
                                                        <h6 className='m-2'>ERC721 Auctions</h6>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item action className='menuListItem' onClick={handleClose} as={Link} to="/721Property">
                                                        <h6 className='m-2'>ERC721 Property</h6>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item action className='menuListItem' onClick={handleClose} as={Link} to="/721Cache">
                                                        <h6 className='m-2'>ERC721 Caches</h6>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to="/721Manager">
                                                        <h6 className='m-2'>ERC721 Manager</h6>
                                                    </ListGroup.Item>

                                                    <hr />
                                                    <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to={'/0xc9aA9940cc8bCacE07121A68521e4755aABB8e1b/tokens'}>
                                                        <h6 className='m-2'>TEST: Pfau LIVE Polygon (Tokens)</h6>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </>

                                ) : (
                                    <>

                                    </>
                                )
                            }
                        </Accordion>

                    </div>
                    {/* Bottom Stuff */}
                    <div style={{ backgroundColor: '#111', width: '100%' }}>
                        <ListGroup.Item className='menuListItem' onClick={signMessage} >
                            <h6 className='m-2'>
                                {activeSignature && activeSignature ? (
                                    <>
                                        <span style={{ fontSize: '10px' }} class="text-success logged-in">●</span> Signature Active
                                    </>
                                ) : (
                                    <>
                                        <span style={{ fontSize: '10px' }} class="text-danger logged-in">●</span> Signature Expired
                                    </>
                                )}
                                <div hidden >
                                    Session: {appContext.sessionMessage}<br />
                                    Session: {appContext.sessionSignedMessage}
                                </div>

                            </h6>
                        </ListGroup.Item>

                        {props.account !== '' ? (
                            authenticated ? (
                                <>
                                    <ListGroup.Item hidden className='menuListItem' onClick={handleClose} as={Link} to={'/u/' + props.account + '/messages'}>
                                        <h6 className='m-2'>Messages</h6>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to="/721Manager">
                                        <h6 className='m-2'>ERC721 Manager</h6>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='menuListItem' onClick={handleClose} as={Link} to="/1155Manager">
                                        <h6 className='m-2'>ERC1155 Manager</h6>
                                    </ListGroup.Item>
                                    <ListGroup.Item action className='bg-dark text-light m-0 p-0' >
                                        <Button style={{ height: '75px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="dark" onClick={() => { logout(); }}>
                                            <strong>Logout</strong>
                                        </Button>
                                    </ListGroup.Item>
                                    <Button hidden style={{ height: '75px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="dark" onClick={() => { props.unloadWallet() }}>
                                        <strong>Logout</strong>
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <ListGroup hidden variant='flush' horizontal className='bg-dark text-light'>
                                        <ListGroup.Item action className='menuListItem' onClick={handleClose} as={Link} to={'/about'}>
                                            <h6 className='m-2'>About</h6>
                                        </ListGroup.Item>
                                        <ListGroup.Item action className='menuListItem' onClick={handleClose} as={Link} to={'/faq/'}>
                                            <h6 className='m-2'>FAQ</h6>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </>
                            )
                        ) : (
                            <>

                            </>
                        )
                        }
                        <MenuFooter></MenuFooter>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>



            <Offcanvas hidden style={{ backgroundColor: '#111', zIndex: 9999 }} className='bg-dark text-light' show={show2} placement='end' onHide={handleClose2}>
                <Offcanvas.Header hidden closeButton>
                    <Offcanvas.Title className='p-0 m-0'>
                        Cache Map
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0 bg-dark'>
                    <div style={{ position: 'absolute', left: '10px', top: '10px', zIndex: '3000', cursor: 'pointer' }} onClick={handleClose2}>
                        <XCircleIcon></XCircleIcon>
                    </div>
                    <Map
                        provider={osm}
                        height={`400px`}
                        defaultCenter={[50.879, 4.6997]}
                        defaultZoom={6}
                        center={center}
                        zoom={zoom}
                        animate={true}
                        zoomSnap={true}
                        minZoom={3}
                        maxZoom={18}
                        mouseEvents={true}
                        touchEvents={true}
                        //metaWheelZoom={true}
                        //metaWheelZoomWarning={'Meta Zoom'}
                        twoFingerDrag={false}
                        onBoundsChanged={({ center, zoom }) => {
                            setCenter(center)
                            setZoom(zoom)
                        }}
                        onClick={handleMapClick}
                        className='bw'
                        style={{ overflowY: 'clip' }}
                    >
                    </Map>
                    <ListGroup variant='flush' horizontal style={{ width: '100%' }}>
                        <ListGroup.Item action onClick={() => { }} className='menuListItem'>Set</ListGroup.Item>
                        <ListGroup.Item action onClick={() => { }} className='menuListItem'>Claim</ListGroup.Item>
                        <ListGroup.Item action onClick={() => { }} className='menuListItem'>Search</ListGroup.Item>
                        <ListGroup.Item action onClick={() => { }} className='menuListItem'>...</ListGroup.Item>
                    </ListGroup>
                    <ListGroup variant='flush' defaultActiveKey="#link1">
                        {
                            authenticated ? (
                                <>
                                    <Button style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="secondary" >
                                        Logged in Function
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="dark" onClick={() => { console.log('click'); }}>
                                        Set Location
                                    </Button>
                                    <Button style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="dark" onClick={() => { console.log('click'); }}>
                                        Verfiy Location with Trusted Agent (Server)
                                        Get on the list. Make sure you are mobile.
                                    </Button>
                                    <Button style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="success" onClick={handleClose}>
                                        Now go Claim Claim Modal
                                    </Button>
                                </>
                            )

                        }
                    </ListGroup>
                    <ListGroup.Item className='menuListItem' onClick={handleClose2} as={Link} to="/claim">
                        <span className='text-muted' style={{ fontSize: '12px' }}>Range Check </span><br />
                        <span>.00008 km</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='menuListItem' onClick={handleClose2} as={Link} to="/claim">
                        <span className='text-muted' style={{ fontSize: '12px' }}>Search Result </span><br />
                        <span>Nearby List</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='menuListItem' onClick={handleClose2} as={Link} to="/claim">
                        <span className='text-muted' style={{ fontSize: '12px' }}>Search Result </span><br />
                        <span>Result</span>
                    </ListGroup.Item>
                    <ListGroup.Item className='menuListItem' onClick={handleClose2} as={Link} to="/cachemap">
                        <h6 className='m-2'>Full Page Map</h6>
                    </ListGroup.Item>
                </Offcanvas.Body>
            </Offcanvas>



            <Offcanvas style={{ zIndex: 9999, backgroundColor: '#111' }} className='bg-dark text-light' show={show3} placement='end' onHide={handleClose3}>
                <Offcanvas.Header hidden closeButton>
                    <Offcanvas.Title className='p-0 m-0'>
                        Scan Claim
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className='p-0 ' style={{ backgroundColor: '#111' }}>
                    <div style={{ position: 'absolute', left: '10px', top: '10px', zIndex: '3000', cursor: 'pointer' }} onClick={handleClose3}>
                        <XCircleIcon></XCircleIcon>
                    </div>
                    {/* <div className='m-0 p-0' style={{ borderRadius:'100%', aspectRatio:'1', overflow:'hidden', width: '100%' }}> */}
                    <div className='m-0 p-0' style={{ aspectRatio: '1', overflow: 'hidden', width: '100%' }}>
                        <QrReader
                            //resolution={1000}
                            delay={300}
                            onError={handleError}
                            onScan={handleScan}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div hidden style={{ textAlign: 'center' }}>
                        {result ? (
                            'Found'
                        ) : (
                            'Scanning...'
                        )}

                    </div>
                    <ListGroup variant='flush' defaultActiveKey="#link1">
                        {authenticated ? (
                            <>
                                {result ? (
                                    <>
                                        <Button style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="dark" >
                                            {props.account == '' ? 'Connect Wallet' : props.account && TruncateSimple(result, 12)}
                                        </Button>
                                        <Button onClick={() => { goLink(); handleClose3() }} style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="success" >
                                            Go
                                        </Button>
                                    </>
                                ) : (
                                    <div className='m-4' style={{ textAlign: 'center' }}>
                                        <span style={{ fontSize: 'bold' }}>Scanning...</span>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {result ? (
                                    <>
                                        <Button style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="dark" >
                                            {props.account == '' ? 'Connect Wallet' : props.account && TruncateSimple(result, 12)}
                                        </Button>

                                        <Button onClick={() => { goLink(); handleClose3() }} style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} variant="success" >
                                            Go
                                        </Button>
                                    </>
                                ) : (
                                    <div className='m-4' style={{ textAlign: 'center' }}>
                                        <span style={{ fontSize: 'bold' }}>Scanner on...</span>
                                    </div>
                                )}
                            </>
                        )
                        }

                    </ListGroup>
                    <div hidden className='text-light'>
                        <ul>
                            <li>{result}</li>
                            <li>{id}</li>
                            <li>{contract}</li>
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Menu;




function MenuFooter(props) {
    return (
        <div className='bg-dark' style={{ backgroundColor: '#111', marginTop: 'auto', flexGrow: '1', width: '100%' }}>
            <div className='text-muted' style={{ backgroundColor: '#111', paddingTop: '10px', paddingBottom: '10px', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', flexWrap: 'wrap' }}>

                <a hidden className='px-1' target='_blank' title='Twitter' href='#'><TwitterIcon /></a>
                <a className='px-1' target='_blank' title='Instagram' href='https://www.instagram.com/vizcache/'><img src='/img/ig2.png' height='32px' /></a>
                <a className='px-1' target='_blank' title='Opensea.io' href='https://opensea.io/collection/vizcache-sketchbook-mmxxii'><img src='/img/osea.png' height='32px' /></a>
                <a className='px-1' target='_blank' title='Email' href='mailto:hello@vizcache.com'><AtSignIcon /></a>
            </div>
            <div hidden className='text-muted' style={{ paddingTop: '10px', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', flexWrap: 'wrap' }}>

                <Link className='text-light' to='/about'>About</Link>
                <Link className='text-light' to='/privacy-policy'>Privacy</Link>
                <Link className='text-light' to='/tc'>T&C</Link>
                <Link className='text-light' to='/faq'>FAQ</Link>
            </div>
            <div style={{ backgroundColor: '#111', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <span style={{ fontSize: '10px' }}> ©MMXXII Vizcache. All Rights Reserved. </span>
                <span className='text-muted' style={{ fontSize: '10px' }}> Version 0.0.8 </span>
            </div>
        </div>
    )
}

