import React from "react";
//import Image from './4sq.png'
import Image from './koru2_dk.png'
import VCZ from './Vizcache2_w.png'
import { AppContext } from '../../App';
import { isMobile } from 'react-device-detect';


const Grid = ({ size = 32, color = "#000" }) => (<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="3" width="7" height="7"></rect><rect x="14" y="3" width="7" height="7"></rect><rect x="14" y="14" width="7" height="7"></rect><rect x="3" y="14" width="7" height="7"></rect></svg>);

function Logo() {
    const appContext = React.useContext(AppContext);

    return (
        <>


            {isMobile ? (
                <>
                    <div className=' m-3' style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                        <span style={{ fontWeight: '800', fontSize: '24px' }}>{appContext.appName}</span>
                        <span style={{ fontWeight: '300', fontSize: '12px' }}>Ultra Premium Digital Property</span>
                    </div>
                </>
            ) : (
                <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                        <img hidden id='mainLogo' src={Image} width='42px' height='42px' />
                        <img hidden src={VCZ} height='52px' />

                        <div hidden className='btn-4 p-0 m-3  shadow' style={{ height: '38px', textAlign: 'center', borderRadius: '5px', aspectRatio: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                to="/"
                                className='shadow menuThumbnail p-0 '
                                style={{ opacity: 0.6, height: '36px', textAlign: 'center', borderRadius: '10px', backgroundColor: '#000', background: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url('')`, backgroundSize: 'cover', backgroundPosition: 'center', aspectRatio: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            </div>

                        </div>
                        <div className=' m-3' style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'column' }}>
                            <span style={{ fontWeight: '800', fontSize: '24px' }}>{appContext.appName}</span>
                            <span style={{ fontWeight: '300', fontSize: '12px' }}>Ultra Premium Digital Property</span>
                        </div>

                    </div>
                </>
            )}


        </>

    )
}


export default Logo;