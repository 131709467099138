import React, { useState, useEffect, Suspense } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
  Outlet,
  Link,
  useParams
} from "react-router-dom";
import { isDesktop, isBrowser, isMobile, isIOS, isChrome, isEdge, isFirefox, isSafari, isMobileSafari, isOpera, isTablet } from 'react-device-detect';
import { TwitterIcon, AtSignIcon } from './components/_common/Icons'


import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { Sanctum, useSanctum } from "./hooks/useSanctum";



//WEB3
import Web3 from "web3";//"web3/dist/web3.min";//"web3";
import { ethers } from "ethers";

import detectEthereumProvider from '@metamask/detect-provider'


import Web3Modal from "web3modal";
import Fortmatic from "fortmatic";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletConnect from "walletconnect";

import WalletConnectProviderMatic from "@maticnetwork/walletconnect-provider"
import Matic from "@maticnetwork/maticjs"

import Torus from "@toruslabs/torus-embed";
import ethProvider from "eth-provider";

import ReactGA from 'react-ga';
//PAGES
import Menu from './menus/MenuAlpha';
const Home = React.lazy(() => import('./pages/public/Home'));
const About = React.lazy(() => import('./pages/public/site/About'));
const FAQ = React.lazy(() => import('./pages/public/site/FAQ'));


//const Home2 = React.lazy(() => import('./pages/public/Home2'));
//const Scan = React.lazy(() => import('./pages/public/Scan'));
//const Claim = React.lazy(() => import('./pages/public/Claim'));
//const Payments = React.lazy(() => import('./pages/public/Payments'));
const Members = React.lazy(() => import('./pages/public/Members'));

const Messages = React.lazy(() => import('./pages/public/Messages'));



const Login = React.lazy(() => import('./pages/public/Login'));
const Profile = React.lazy(() => import('./pages/public/Profile'));
const Contracts = React.lazy(() => import('./pages/public/site/Contracts'));
const Collections = React.lazy(() => import('./pages/public/site/Collections'));
const CacheMap = React.lazy(() => import('./pages/public/Map'));
const PublicPosts = React.lazy(() => import('./pages/public/PublicPosts'));
const PublicPostDetail = React.lazy(() => import('./pages/public/PublicPostDetail'));








//ERC20VCZ
const ERC20 = React.lazy(() => import('./pages/module/_ERC/ERC20/ERC20_VZC'));


//ERC721
const Manager = React.lazy(() => import('./pages/module/Manager/Manager'));

const ManagerMinter = React.lazy(() => import('./pages/module/Manager/Manager_Minter'));
const Manager1155 = React.lazy(() => import('./pages/module/Manager/Manager_Minter1155'));




const Drafter = React.lazy(() => import('./pages/module/Minter/Drafter'));
const PrivatePosts = React.lazy(() => import('./pages/module/Minter/PrivatePosts'));
const PrivatePost = React.lazy(() => import('./pages/module/Minter/PrivatePost'));
const Uploader = React.lazy(() => import('./pages/module/Minter/Uploader'));
const Manifest = React.lazy(() => import('./pages/module/Minter/Manifest'));
const Cloner = React.lazy(() => import('./pages/module/Minter/Cloner'));
const Mint = React.lazy(() => import('./pages/module/Minter/Mint'));


//Dynamic URL Based Componenet for ERC721
//Marketplace Multi-Contract 721/1155/3664
const Collection = React.lazy(() => import('./pages/module/Marketplace/Collection'));
const ContractExplorer = React.lazy(() => import('./pages/module/Marketplace/ContractExplorer'));
//const Tokens2 = React.lazy(() => import('./pages/module/Marketplace/Tokens'));


const Token2 = React.lazy(() => import('./pages/module/Marketplace/Token'));
const Rights2 = React.lazy(() => import('./pages/module/Marketplace/Rights'));
const Cause2 = React.lazy(() => import('./pages/module/Marketplace/Cause'));
const Pool2 = React.lazy(() => import('./pages/module/Marketplace/Pool'));
const Trade2 = React.lazy(() => import('./pages/module/Marketplace/Trade'));
const Auction2 = React.lazy(() => import('./pages/module/Marketplace/Auction'));
const Property2 = React.lazy(() => import('./pages/module/Marketplace/Property'));
const Cache2 = React.lazy(() => import('./pages/module/Marketplace/Cache'));
const Access2 = React.lazy(() => import('./pages/module/Marketplace/Access'));
const SmartFrame2 = React.lazy(() => import('./pages/module/Marketplace/Smartframe'));


const ClaimMint = React.lazy(() => import('./pages/module/Marketplace/Claim/ClaimMint'));


//PRIVATE LOGIN
const Dashboard = React.lazy(() => import('./pages/private/Dashboard'));
//Site Admin Tools, Maybe Another App.
//const AdminChains = React.lazy(() => import('./pages/admin/AdminChains'));


/*
//TODO: ERC3664
const Metacore = React.lazy(() => import('./pages/module/ERC3664/Metacore'));
const SERC721 = React.lazy(() => import('./pages/module/ERC3664/SERC721'));
const ComposedTokens = React.lazy(() => import('./pages/module/ERC3664/ComposedToken'));

const SManager = React.lazy(() => import('./pages/module/ERC3664/Manager'));
const SMint = React.lazy(() => import('./pages/module/ERC3664/Mint'));
const STokens = React.lazy(() => import('./pages/module/ERC3664/Tokens'));
const SToken = React.lazy(() => import('./pages/module/ERC3664/Token'));
*/


const axios = require('axios').default;


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
let production = true;
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Switches to LIVE init urls
//https://stackoverflow.com/questions/50502664/how-to-update-the-context-value-in-a-provider-from-the-consumer

let Addresses;
if (production) {
  //LIVE REAL CHAIN 
  Addresses = {
    ERC20_VZC: '0xb05784a8374F3B3D060bFE71b2C8Ba69D65903Bb', //DEPLOYED

    ERC721NFT: '0x0A8B7111b4eb43886900E97d8f399F7Acf8E7d5C', //DEPLOYED
    ERC721NFTSKETCH: '0x8a8F4eC3288ddb63BAF881900Fe239930Ededd6C', //DEPLOYED

    ERC721NFTClaim: '0x68C7656c3C48a507638a687cb1c25ABB113aCd75', //DEPLOYED
    ERC721Gallery: '0x882b9D85EBA60A61b1835C3A7C54B5317027a8C3', //DEPLOYED

    ERC721Property: '0xc17Ce4d85121981a337384bdcd262AFA21Cf7965', //Not Deployed
    ERC721Vizcache: '0xad75421579247317fea8a057e4666b96b39b3a00', //DEPLOYED

    ERC1155NFT: '0x660ef4f6ef4c3250ad03976e80b5428a853cc3fd', //DEPLOYED
    ERC1155ECO: '0xece6dc97e419e2ed45635098b7cce2d07898326e', //DEPLOYED
    ERC1155Market: '0x67b1C277BF77A6A828feFf801eC58c609A266bFb', //NO // #3rd Order with Sellers Array Too big and complicated
    ERC1155Pool: '0x578b16ecc5e26de91f3358722e1c55cdc5973ab8',  //DEPLOYED // With Royalty Rights 
    ERC1155Cause: '0xce09ccff828581a42dcc8ff9afb5642ff422bdf1',  //DEPLOYED //Marketplace + Charity Cause

    ERC3664Meta: '0xb816a9b92EdD032486AEd5c8ec8aE746116eEa75', //NO
    ERC3664MetaPfau: '0x798C1045CEACAde8880526f5D2513402B19278B3', //NO
    CustomMetadata: '0x807378011cE014011516Ef052BE10789b460c59C',  //NO
  }
} else {
  //LOCAL CHAIN 
  Addresses = {
    ERC20_VZC: '0x7A0fA16927A8233146736957dDEB7fccCdF86F94',

    ERC721NFT: '0xdC2D3d58c9C73103c7A963d0Ecb9c4082B63b3B8',
    //ERC721NFT2: '0xdC2D3d58c9C73103c7A963d0Ecb9c4082B63b3B8',
    ERC721NFTClaim: '0x4AD4B941896CcAf44C704C934489CbF03832cA89',

    ERC721Gallery: '0x9433a955784b936135478e0c09f01E9D3DC7fC06',

    //ERC721Rental: '0xBc2F1427fB9C9c83DA26A7014476280b8Dd1cca7',
    ERC721Property: '0xc17Ce4d85121981a337384bdcd262AFA21Cf7965',
    //ERC721Vizcache: '0xB5d4f928Dd50EF92dD9C5037c20B7d9b313b31AC',
    ERC721Vizcache: '0x114ac4Fb2b204fBeBC9922BDC0e315793615300C', //Agent  0x114ac4Fb2b204fBeBC9922BDC0e315793615300C



    ERC1155NFT: '0x0203d81ecD4813Ec10d3acd443BB06d62400eF1F',
    ERC1155Market: '0x67b1C277BF77A6A828feFf801eC58c609A266bFb', // #3rd Order with Sellers Array
    ERC1155Pool: '0xb4579055A440FbE2C761Ec6fb5a35F7932FabbB1', // With Royalty Rights 

    ERC3664Meta: '0xb816a9b92EdD032486AEd5c8ec8aE746116eEa75',
    ERC3664MetaPfau: '0x798C1045CEACAde8880526f5D2513402B19278B3',
    CustomMetadata: '0x807378011cE014011516Ef052BE10789b460c59C',
  }
}


const contextObject = {
  contractAddr: Addresses,
  explorerBase: 'https:etherscan.io/',
  marketplaceBase: 'https://opensea.io/',
  cdnGateway: '',
  ipfsGateway: 'https://ipfs.infura.io/ipfs/', //Cloudflare readonly
  menuHeight: '62px',
  appName: 'VizCaché',//é

  //
  sessionSignedMessage: '',
  sessionMessage: '',
  setSessionMessage: (msgValue, signMsgValue) => { // Session is 5min/300000ms Set message with furture exiprations from Profile and Message Component, make user expirence easier since metamsk signing is buggy and a pain point. session 
    contextObject.sessionMessage = msgValue;
    contextObject.sessionSignedMessage = signMsgValue;
  },

  //Enable for public / Not Admins
  enableTrade: true,
  enableAuction: true,
  enableTrade1155: false,
  enableAuction1155: false,

  enableProperty: false,
  enableCache: true,
  enableAccess: true,

  enableContracts: true,
  enableContractExplorer: true, //hardcoded true

  enableMessages: true,
}

let sanctumConfig;
if (production) {
  sanctumConfig = {
    //apiUrl: "http://localhost:8000",
    apiUrl: "https://api.vizcache.com",
    csrfCookieRoute: "sanctum/csrf-cookie",
    signInRoute: "api/login",
    signOutRoute: "api/logout",
    userObjectRoute: "api/user",
  };
} else {
  sanctumConfig = {
    //apiUrl: "http://192.168.0.237:80",
    apiUrl: "http://localhost:8000",
    //apiUrl: "https://api.vizcache.com",
    csrfCookieRoute: "sanctum/csrf-cookie",
    signInRoute: "api/login",
    signOutRoute: "api/logout",
    userObjectRoute: "api/user",
  };
}



if (production) {
  contextObject.apiRoot = 'https://api.vizcache.com/api/';
  contextObject.storageRoot = 'https://api.vizcache.com/';
  contextObject.privateRoot = 'https://api.vizcache.com/private/';
  contextObject.appRoot = 'https://www.vizcache.com/';
  contextObject.apiUrl = "https://api.vizcache.com/";
} else {
  //Artisan Serve
  contextObject.apiRoot = 'http://localhost:8000/api/';
  contextObject.storageRoot = 'http://localhost:8000/';
  contextObject.privateRoot = 'http://localhost:8000/private/';
  contextObject.appRoot = 'http://localhost:8000/';
  contextObject.apiUrl = "http://localhost:8000/";

  //PI
  /*
  contextObject.apiRoot = 'http://192.168.0.237:80/api/';
  contextObject.appRoot = 'http://192.168.0.237:80/';
  contextObject.storageRoot = 'http://192.168.0.237:80/';
  contextObject.privateRoot = 'http://192.168.0.237:80/private/';
  contextObject.apiUrl = "http://192.168.0.237:80/";
  */
}


/*
function setSessionMessage(msgValue, signMsgValue) {
  contextObject.sessionMessage = msgValue;
  contextObject.sessionSignedMessage = signMsgValue;

}
*/



export const AppContext = React.createContext(contextObject);
AppContext.displayName = 'AppContext';
//------------------------------------------------------------------------------------------

function App() {
  //const [authenticated, setAuthenticated] = useState(false);
  let location = useLocation();

  /*State*/////////////////////////////////////////////
  const [account, setAccount] = useState('');
  const [authorized, setAuthorized] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const [liveMode, setLiveMode] = useState(false);
  ////////////////////////////////////////////////////////////////////////////////////////////

  const [web3ModalInstance, setWeb3ModalInstance] = useState('');
  const [WCinstance, setInstance] = useState('');

  const [provider, setProvider] = useState('');
  const [signer, setSigner] = useState('');

  const [activeKey, setActiveKey] = useState(0);





  ///
  /*Logic*/
  useEffect(() => {

    /*
    Look up the Access Control Rental Contract for the specific token.
    Check owner. then review md5 of index to make sure site is same. 
    Token access ipfs Application portals. That are then crypto loged int connected back to web3.0 auth servers. 
    */
    //setLiveMode(false);
    //if (account === '0x639f745b2c3dC0D8413EA87d42fd491005137A88' || account === '0xfB9b36325aB8f020894bBBD27a6481C921225141' || account === '0x895f296F6575F5d7dc4ebF78e06afE1a804a9318') {
    //get memebers address list and merge with whitelist 
    let whitelist = [
      '0x15495580Aa1B3523de23beE0Cb13db9FabF099FF', //Ganache Dev
      '0xf6BFCcBCB1c6762EE9BDfA23df18761428Acd91A',
      '0x9a19a815Fa8117D0DDa5A205fc9955D1f23De4C9',
      '0xD85a82BeA3a1126a39C159CdA0193Ec17E8c2aBF',
      '0xDea51618b866ae05400a094F7c31e4a3E42EB938',//VizCache Poly
      '0xD0990c97C0c55E378850D1E4Db2BD4ad3a4B0039',//VizCache
      '0xc0C266bF4A085B1FBe96886FcB4a7163049Fc67b',//Joan 
      '0x639f745b2c3dC0D8413EA87d42fd491005137A88',//Pfau
      '0x1B4003e706E66F739Ebe0Bb5FAcec85d57B64131',//Pfau Ipad
      '0xfB9b36325aB8f020894bBBD27a6481C921225141',//Vivas   
      '0x8E76324D9a64530f861E5AcB3A1FA2AC7559C87C',//Seamo
      //?Taylor??

    ]

    whitelist.forEach(address => {
      if (account == address) {
        setLiveMode(true);
      }
    });

    /*
    if (
      account === '0x15495580Aa1B3523de23beE0Cb13db9FabF099FF' || //Ganache Dev
      account === '0xf6BFCcBCB1c6762EE9BDfA23df18761428Acd91A' ||
      account === '0x9a19a815Fa8117D0DDa5A205fc9955D1f23De4C9' ||
      account === '0xD85a82BeA3a1126a39C159CdA0193Ec17E8c2aBF' ||
      account === '0xDea51618b866ae05400a094F7c31e4a3E42EB938' || //VizCache Poly
      account === '0xD0990c97C0c55E378850D1E4Db2BD4ad3a4B0039' || //VizCache
      account === '0xc0C266bF4A085B1FBe96886FcB4a7163049Fc67b' || //Joan 
      account === '0x639f745b2c3dC0D8413EA87d42fd491005137A88' || //Pfau
      account === '0x1B4003e706E66F739Ebe0Bb5FAcec85d57B64131' || //Pfau Ipad
      account === '0xfB9b36325aB8f020894bBBD27a6481C921225141'  //Vivas 
      //?Taylor??

    ) {
      //setAuthorized(true);
      //This gets set above ^^^^
      setLiveMode(true);
      //console.log(liveMode);
    }

    //if (location.pathname == '/claim-membership'

    //loadChain();
    */
  }, [account])


  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Load Wallet Logic

  async function loadWallet() {
    //Check if mobile and if matic.
    // isDesktop, isBrowser, isMobile, isIOS, isChrome, isEdge, isFirefox, isSafari, isMobileSafari, isOpera, isTablet
    let matic = true;

    if (isMobile) {
      if (isIOS || isMobileSafari) {
        //IOS
        //loadWCMatic();
        loadWC();
        //loadMetamask2();
      } else if (isChrome) {
        //CHROME
        //loadMetamask2();
        //loadWCMatic();
        loadWC();
      } else {
        //MOBILE DEFAULT
        //loadMetamask2();
        //loadWCMatic();
        loadWC();
      }
    } else if (isDesktop) {
      //Desktop
      if (isSafari) {
        //OSX SAFARI
        loadWC();
      } else if (isChrome) {
        //CHROME
        loadMetamask2();
        //loadWC();
      } else if (isFirefox) {
        //FIREFOX
        loadMetamask2();
        //loadWC();
      } else if (isEdge) {
        //EDGE
        //loadMetamask2();
        loadWC();
      } else {
        //DESKTOP DEFAULT
        loadWC();
      }
    } else {
      //MasterDefault
      loadWC();
    }
    /**/
    //
    //loadWC();
    //loadModal3();
    //loadMetamask();
    //loadMetamask2();

  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //Metamask
  async function loadMetamask() {
    const instance = await detectEthereumProvider()

    if (instance) {
      console.log('Ethereum successfully detected!')

      // From now on, this should always be true:
      // provider === window.ethereum

      // Access the decentralized web!

      // Legacy providers may only have ethereum.sendAsync
      const chainId = await instance.request({
        method: 'eth_chainId'
      })

      //await instance.enable();
      setInstance(instance);



      //const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
      const provider = new ethers.providers.Web3Provider(instance, "any");
      setProvider(provider);
      //
      await provider.send("eth_requestAccounts", []);
      //
      const signer = await provider.getSigner();
      setSigner(signer);
      
      //
      const account = await signer.getAddress();
      setAccount(account);
    } else {

      // if the provider is not detected, detectEthereumProvider resolves to null
      console.error('Please install MetaMask!')//, error)
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //Metamsk Async for Mobile
  async function loadMetamask2() {
    const instance = await detectEthereumProvider();
    console.log(instance);

    if (window.ethereum) {
      handleEthereum();
    } else {
      window.addEventListener('ethereum#initialized', handleEthereum, {
        once: true,
      });

      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 3000); // 3 seconds
    }

    async function handleEthereum() {
      const { ethereum } = window;
      if (ethereum && ethereum.isMetaMask) {
        console.log('Ethereum successfully detected!');
        // Access the decentralized web!
        const provider = new ethers.providers.Web3Provider(instance, "any");
        setProvider(provider);
        //

        await provider.send("eth_requestAccounts", []);


        const signer = provider.getSigner();
        setSigner(signer);
        //
        const account = signer.getAddress().then((account) => {
          setAccount(account);
        });


      } else {
        console.log('Please install MetaMask!');
      }
    }
    /*
      const instance = await detectEthereumProvider()
  
      if (instance) {
        console.log('Ethereum successfully detected!')
        // From now on, this should always be true:
        // provider === window.ethereum
  
        // Access the decentralized web!
  
        // Legacy providers may only have ethereum.sendAsync
        const chainId = await instance.request({
          method: 'eth_chainId'
        })
  
        //await instance.enable();
        setInstance(instance);
  
  
  
        //const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const provider = new ethers.providers.Web3Provider(instance, "any");
        setProvider(provider);
        //
        const signer = await provider.getSigner();
        setSigner(signer);
        //
        const account = await signer.getAddress();
        setAccount(account);
      } else {
  
        // if the provider is not detected, detectEthereumProvider resolves to null
        console.error('Please install MetaMask!')//, error)
      }
      */

    ////////////////////////////////////////////

    window.ethereum.on("network", (newNetwork, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork) {
        window.location.reload();
      }
    });


    // Subscribe to accounts change
    window.ethereum.on("accountsChanged", (accounts) => {
      //const signer = await provider.getSigner();
      //setSigner(signer);

      //const account = accounts[0];
      //const account = signer.getAddress();
      //let ICAP = ethers.utils.getIcapAddress(accounts);
      //console.log('ICAP', ICAP);
      //let checkSumAddress = ethers.utils.getAddress(ICAP);
      //console.log('checkSumAddress', checkSumAddress);
      //setAccount(checkSumAddress);
      //let accounts2 =  signer.getAddress();

      logout();
      //window.location.replace('/');
      //setAccount(accounts);
      //window.location.reload();

      //console.log('Accounts', accounts);
    });

    // Subscribe to chainId change
    window.ethereum.on("chainChanged", (chainId) => {
      console.log('ChainId', chainId);
      window.location.reload();
    });

    window.ethereum.on("connect", (info) => {
      console.log('Info', info);
    });

    // Subscribe to provider disconnection
    window.ethereum.on("disconnect", (error) => {
      console.log('Error', error);
    });

  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //WALLET CONNECT PROVIDER
  async function loadWC() {
    //MOBILE DIRECT WALLET CONNECT 
    //Attemp1: Create WalletConnect Provider
    /*
    let instance = new WalletConnectProvider({
      //infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
      rpc: {
        1: "https://mainnet.infura.io/v3/e585108d66ca43f38587a9ef21dffb50",
        //3: "https://ropsten.mycustomnode.com",
        //100: "https://dai.poa.network",
        137: "https://polygon-mainnet.infura.io/v3/e585108d66ca43f38587a9ef21dffb50"
      },
    });
    */

    //Moralis
    let instance = new WalletConnectProvider({
      //let instance = new WalletConnect({
      //infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
      rpc: {
        1: "https://speedy-nodes-nyc.moralis.io/44c1ca25ecfda5dfbe6499da/eth/mainnet",
        //3: "https://ropsten.mycustomnode.com",
        //100: "https://dai.poa.network",
        137: "https://speedy-nodes-nyc.moralis.io/44c1ca25ecfda5dfbe6499da/polygon/mainnet",
        11297108109: "https://palm-mainnet.infura.io/v3/e585108d66ca43f38587a9ef21dffb50"
      },
    });

    //  Enable session (triggers QR Code modal)
    await instance.enable();
    //await instance.connect();

    setInstance(instance);


    //const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const provider = new ethers.providers.Web3Provider(instance, "any");
    setProvider(provider);
    //
    const signer = await provider.getSigner();
    setSigner(signer);
    //
    const account = await signer.getAddress();
    setAccount(account);

    ////////////////////////////////////////////

    provider.on("network", (newNetwork, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork) {
        window.location.reload();
      }
    });


    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      //const signer = await provider.getSigner();
      //setSigner(signer);

      //const account = accounts[0];
      //const account = signer.getAddress();
      //let ICAP = ethers.utils.getIcapAddress(accounts);
      //console.log('ICAP', ICAP);
      //let checkSumAddress = ethers.utils.getAddress(ICAP);
      //console.log('checkSumAddress', checkSumAddress);
      //setAccount(checkSumAddress);
      //let accounts2 =  signer.getAddress();
      //window.location.reload();
      //setAccount(accounts);


      //console.log('Accounts', accounts);
      //window.location.replace('/');
      logout();
      //

      ///!! Need to logout of sanctum on metamask change!!!
    });

    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      console.log('ChainId', chainId);
    });

    provider.on("connect", (info) => {
      console.log('Info', info);
    });

    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log('Error', error);
    });


  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //MATIC WALLET CONNECT PROVIDER
  async function loadWCMatic() {
    //MOBILE DIRECT WALLET CONNECT 
    /*Attemp1: Create WalletConnect Provider
    instance = new WalletConnectProvider({
      //infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
      rpc: {
        //1: "https://mainnet.infura.io/v3/e585108d66ca43f38587a9ef21dffb50",
        //3: "https://ropsten.mycustomnode.com",
        //100: "https://dai.poa.network",
        137: "https://polygon-mainnet.infura.io/v3/e585108d66ca43f38587a9ef21dffb50"
      },
    });

    //  Enable session (triggers QR Code modal)
    await instance.enable();
    setInstance(instance);
    */
    const instance = new WalletConnectProviderMatic(
      {
        host: `https://polygon-mainnet.infura.io/v3/e585108d66ca43f38587a9ef21dffb50`,
        callbacks: {
          onConnect: console.log('connected'),
          onDisconnect: console.log('disconnected!')
        }
      }
    )

    //await instance.enable();
    setInstance(instance);

    //const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const provider = new ethers.providers.Web3Provider(instance, "any");
    setProvider(provider);
    //
    const signer = await provider.getSigner();
    setSigner(signer);
    //
    const account = await signer.getAddress();
    setAccount(account);

  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  //MODAL 3 | NO Polygon 137 on WC option?
  async function loadModal3() {
    //https://github.com/Web3Modal/web3modal
    //https://codesandbox.io/s/8rg3h?file=/src/index.js
    console.log('Modal_3_Loading...');
    //let web3Modal = false;

    let instance;

    const customNodeOptions = {
      rpcUrl: 'https://testnet2.matic.network', // your own node url
      chainId: 80001 // chainId of your own node
    }

    const providerOptions = {
      /* See Provider Options Section */
      fortmatic: {
        package: Fortmatic, // required
        options: {
          key: "pk_test_0386056F873F2801", // required
        }
      },
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          rpc: {
            1: "https://qfvkkel3yozj.usemoralis.com:2053/server",
            //3: "https://ropsten.mycustomnode.com",
            //100: "https://dai.poa.network",
            137: "https://gy7rimbnhstr.usemoralis.com:2053/server"//!! use this https://speedy-nodes-nyc.moralis.io/44c1ca25ecfda5dfbe6499da/polygon/mainnet
          },

          /*
          rpc: {
            1: "https://mainnet.infura.io/v3/e585108d66ca43f38587a9ef21dffb50",
            //3: "https://ropsten.mycustomnode.com",
            //100: "https://dai.poa.network",
            137: "https://polygon-mainnet.infura.io/v3/e585108d66ca43f38587a9ef21dffb50"
          },*/
          //infuraId: "e585108d66ca43f38587a9ef21dffb50"//"23ELD9Wk6gXBMZJmqmozDA7P31G" // required
        }
      },
      /*
       frame: {
         package: ethProvider // required
       }
       torus: {
         package: Torus, // required
         options: {
           networkParams: {
             //host: "https://testnet2.matic.network", // optional
             //chainId: 1337, // optional
             //networkId: 1337 // optional
           },
           config: {
             //buildEnv: "development" // optional
           }
         }
       }
      */
    };

    const web3Modal = new Web3Modal({
      //network: "mainnet", // optional
      cacheProvider: false, // optional
      providerOptions, // required
      theme: "dark",
      disableInjectedProvider: false
    });
    setWeb3ModalInstance(web3Modal);
    //Only ForDev
    //!!!!!!!!!!!!!!!!
    //await web3Modal.clearCachedProvider();

    //
    instance = await web3Modal.connect();

    //await instance.enable();
    setInstance(instance);



    //const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const provider = new ethers.providers.Web3Provider(instance, "any");
    setProvider(provider);
    //
    const signer = await provider.getSigner();
    setSigner(signer);
    //
    const account = await signer.getAddress();
    setAccount(account);





    ////////////////////////////////////////////

    provider.on("network", (newNetwork, oldNetwork) => {
      // When a Provider makes its initial connection, it emits a "network"
      // event with a null oldNetwork along with the newNetwork. So, if the
      // oldNetwork exists, it represents a changing network
      if (oldNetwork) {
        window.location.reload();
      }
    });


    // Subscribe to accounts change
    window.ethereum.on("accountsChanged", (accounts) => {
      //const signer = await provider.getSigner();
      //setSigner(signer);

      //const account = accounts[0];
      //const account = signer.getAddress();
      //let ICAP = ethers.utils.getIcapAddress(accounts);
      //console.log('ICAP', ICAP);
      //let checkSumAddress = ethers.utils.getAddress(ICAP);
      //console.log('checkSumAddress', checkSumAddress);
      //setAccount(checkSumAddress);
      //let accounts2 =  signer.getAddress();
      setAccount(accounts);
      //window.location.reload();

      console.log('Accounts', accounts);
    });

    // Subscribe to chainId change
    window.ethereum.on("chainChanged", (chainId) => {
      console.log('ChainId', chainId);
    });

    window.ethereum.on("connect", (info) => {
      console.log('Info', info);
    });

    // Subscribe to provider disconnection
    window.ethereum.on("disconnect", (error) => {
      console.log('Error', error);
    });


    //////////////
    //maybe persist in broweser storage persit until logged out.
    //connectWalletHandler();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////
  //UNLOAD
  async function unloadWallet() {
    console.log('UnLoading Wallet...');
    await web3ModalInstance.clearCachedProvider();
    //await web3ModalInstance.disconnect();
    provider = null;
    //instance = null;
    //await WCinstance.disconnect();
    //
    window.location.replace('/');
  }

  //Functions////////////////////////////////////////////////////////////////////////////////////////
  function logout() {
    axios.defaults.headers.post['Accept'] = 'application/json';
    axios.defaults.withCredentials = true;
    axios({
      method: "post",
      url: contextObject.apiRoot + 'logout',//props.appContext.apiGateway + props.modelName,
      //data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        window.location.replace('/');

      })
      .catch(function (response) {
        //handle error
        console.log(response);
      })

  }


  //ANALYTICS TRACKING 
  //let location = useLocation();
  //UA-208908792-1
  ReactGA.initialize('UA-208908792-1');

  useEffect(() => {
    //ga('send', 'pageview');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);




  //RENDER///////////////////////////////////////////////////////////////////////////////////////////
  if (liveMode === true) {
    return (
      <div className="">
        <ScrollToTop />
        <Sanctum config={sanctumConfig}>
          <AppContext.Provider value={contextObject}>
            <Suspense fallback={<Loader />}>
              <Menu account={account} loadWallet={loadWallet} unloadWallet={unloadWallet} user={{}} provider={provider} signer={signer} account={account} />
              <Routes>

                <Route path="/" element={<Home provider={provider} signer={signer} account={account} />} />
                <Route path="/about" element={<About />} />

                <Route path="/faq" element={<FAQ />} />
                <Route path="/cachemap" element={<CacheMap provider={provider} signer={signer} account={account} />} />

                <Route path="/members" element={<Members provider={provider} signer={signer} account={account} />} />

                <Route path="/posts" element={<PublicPosts provider={provider} signer={signer} account={account} />} />
                <Route path="/posts/:id" element={<PublicPostDetail provider={provider} signer={signer} account={account} />} />

                {/* Closed in Alpha 
                <Route path="/scan" element={<Scan provider={provider} signer={signer} account={account} />} />
                <Route path="/geo-claim" element={<Claim provider={provider} signer={signer} account={account} />} />
                <Route path="/payments" element={<Payments provider={provider} signer={signer} account={account} />} />

                <Route path="/posts" element={<PublicPosts />} />
                <Route path="/posts/:id" element={<PublicPostDetail />} />

                Public Posts Feed
                <Route path="/posts" element={<Feed />} />
                <Route path="/posts/{id}" element={<FeedDetail />} />
                */}



                <Route path="/login" element={<Login provider={provider} signer={signer} account={account} />} />

                <Route path="/u/:address" element={<Profile provider={provider} signer={signer} account={account} />} />
                {/* TODO <Route path="/profile/:username" element={<Profile provider={provider} signer={signer} account={account} />} /> */}


                <Route path="/collection/:name" element={<Collection provider={provider} signer={signer} account={account} />} />

                <Route path="/:contract" element={<ContractExplorer provider={provider} signer={signer} account={account} />} />

                {/*DEV <Route path="/:contract/tokens" element={<Tokens2 provider={provider} signer={signer} account={account} />} /> */}

                <Route path="/:contract/:id" element={<Token2 provider={provider} signer={signer} account={account} />} />
                <Route path="/:contract/:id/rights" element={<Rights2 provider={provider} signer={signer} account={account} />} />
                <Route path="/:contract/:id/pool" element={<Pool2 provider={provider} signer={signer} account={account} />} />
                <Route path="/:contract/:id/cause" element={<Cause2 provider={provider} signer={signer} account={account} />} />

                <Route path="/:contract/:id/trade" element={<Trade2 provider={provider} signer={signer} account={account} />} />
                <Route path="/:contract/:id/auction" element={<Auction2 provider={provider} signer={signer} account={account} />} />
                <Route path="/:contract/:id/property" element={<Property2 provider={provider} signer={signer} account={account} />} />
                <Route path="/:contract/:id/cache" element={<Cache2 provider={provider} signer={signer} account={account} />} />
                <Route path="/:contract/:id/smartframe" element={<SmartFrame2 provider={provider} signer={signer} account={account} />} />

                <Route path="/:contract/:id/private" element={<Access2 provider={provider} signer={signer} account={account} />} />



                <Route path="/contracts" element={<Contracts provider={provider} signer={signer} account={account} />} />
                <Route path="/collections" element={<Collections provider={provider} signer={signer} account={account} />} />
                <Route path="/claim-membership" element={<ClaimMint provider={provider} signer={signer} account={account} />} />

                <Route element={<RequireAuth account={account} />}>
                  <Route path="/u/:address/dashboard" element={<Dashboard />} />
                  <Route path="/u/:address/messages" element={<Messages provider={provider} signer={signer} account={account} />} />

                </Route>

                <Route element={<RequireBasicMember account={account} />}>
                  <Route path="/u/:address/posts" element={<PrivatePosts provider={provider} signer={signer} account={account} />} />


                  <Route path="/u/:address/draft" element={<Drafter provider={provider} signer={signer} account={account} />} />
                  <Route path="/u/:address/posts/:id" element={<PrivatePost provider={provider} signer={signer} account={account} />} />
                  <Route path="/u/:address/posts/:id/files" element={<Uploader provider={provider} signer={signer} account={account} />} />
                  {/*<Route path="/u/:address/posts/:id/video" element={<FilesVideo provider={provider} signer={signer} account={account} />} />*/}
                  <Route path="/u/:address/posts/:id/manifest" element={<Manifest provider={provider} signer={signer} account={account} />} />
                  <Route path="/u/:address/posts/:id/cloner" element={<Cloner provider={provider} signer={signer} account={account} />} />
                  <Route path="/u/:address/posts/:id/mint" element={<Mint provider={provider} signer={signer} account={account} />} />

                </Route>


                <Route element={<RequireAdmin account={account} />}>

                  <Route path="/:contract/manager" element={<ManagerMinter provider={provider} signer={signer} account={account} />} />

                  <Route path="/721Manager" element={<Manager provider={provider} signer={signer} account={account} />} />
                  <Route path="/1155Manager" element={<Manager1155 provider={provider} signer={signer} account={account} />} />

                </Route>



                <Route element={<RequireAuth account={account} />}>
                </Route>

                <Route element={<RequireExclusiveMember account={account} />}>
                </Route>

                <Route element={<RequireManager account={account} />}>
                </Route>

                <Route path="*" element={<NotFound />} />

                {/* TODO
                <Route path="/3664Metacore" element={<Metacore />} />
                <Route path="/3664SERC721" element={<SERC721 />} />
                <Route path="/3664ComposedTokens" element={<ComposedTokens />} />

                <Route path="/3664Manager" element={<SManager />} />
                <Route path="/3664Mint" element={<SMint />} />
                <Route path="/3664Tokens" element={<STokens />} />
                <Route path="/3664Tokens/:id" element={<SToken />} />

                other
                <Route path="/admin/chains" element={<AdminChains />} />
                <Route path="/admin/contracts" element={<AdminChains />} />
                <Route path="/admin/collections" element={<AdminCollections />} />
                <Route path="/20Tokens" element={<ERC20 />} />
                */}

                {/*<Route path="/protected" element={<ProtectedPage />} />
                  <Route path="/dashboard" element={<Dashboard />} />*/}

                {/*<Route path="/nft/:contract" element={<Tokens2 />} />
                  <Route path="/:contract/mint" element={<Mint2 provider={provider} signer={signer} account={account} />} />
                */}

              </Routes>
              <div hidden className='m-0' style={{ backgroundColor: '#111', height: '100px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundSize: 'cover', backgroundPosition: 'center', }} >
                <p style={{ color: '#ddd', fontWeight: '200' }}>©MMXXII VizCaché. All Rights Reserved.  </p>
                <div hidden>Developed w/ &hearts; by <a href='https://mpfau.com'>mpfau</a></div>
              </div>
            </Suspense>
          </AppContext.Provider>
        </Sanctum>

      </div>
    );
  } else {
    return (
      <>
        <div className=' text-light' style={{ backgroundColor: '#111', minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1 style={{ fontWeight: '900', fontSize: '48px' }}>VizCaché</h1>
            <p>Alpha</p>
          </div>
          <span className='text-muted mb-4' style={{ fontSize: '16px' }}> Ultra Premium Digital Property</span>

          <button
            onClick={loadWallet}
            style={{ color: '#fff', textDecoration: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            className={'btn-13 glow-on-hover mx-2 my-2'} to='/about' >
            {account == '' ? 'Connect Wallet' : <>Not Authorized for Beta</>}
          </button>
          <Button
            hidden
            onClick={loadWallet}
            style={{ height: '75px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            variant="dark" >
            {account == '' ? 'Connect Wallet' : <>Loading...</>}
          </Button>
          <span className='pt-4 text-muted' style={{ textAlign: 'center', fontSize: '12px' }}>Artist do you want to join our team or community? <br />Gain access by contacting us below.</span>
          <div>
            <div className='text-muted py-4' style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', flexWrap: 'wrap' }}>
              <a hidden className='px-3' style={{ opacity: 0.5 }} target='_blank' title='Twitter' href='#'><TwitterIcon /></a>
              <a className='px-3' target='_blank' title='Instagram' href='https://www.instagram.com/vizcache/'><img src='/img/ig2.png' height='32px' /></a>
              <a className='px-3' style={{ opacity: 0.5 }} target='_blank' title='Opensea.io' href='https://opensea.io/collection/vizcache-sketchbook-mmxxii'><img src='/img/osea.png' height='32px' /></a>
              <a className='px-3' style={{ opacity: 0.5 }} target='_blank' title='Email' href='mailto:hello@vizcache.com'><AtSignIcon /></a>
            </div>
          </div>
        </div>
        <MenuFooter></MenuFooter>
      </>


    )
  }
}

export default App;


//Basic Member
function RequireBasicMember(props) {
  //let auth = useAuth();
  const { authenticated, user, checkAuthentication, signIn3 } = useSanctum();
  console.log(authenticated)
  let location = useLocation();


  let role = user && user.role;
  const verified = role === 'admin' || role === 'site-manager' || role === 'member-exclusive' || role === 'member-basic' ? true : false;

  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (authenticated && !verified) {
    //return <Navigate to={"/u/" + props.account + '/dashboard'} state={{ from: location }} />;
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;

}


//Exclusive Member
function RequireExclusiveMember(props) {
  //let auth = useAuth();
  const { authenticated, user, checkAuthentication, signIn3 } = useSanctum();
  console.log(authenticated)
  let location = useLocation();

  let role = user && user.role;
  const verified = role === 'admin' || role === 'site-manager' || role === 'member-exclusive' ? true : false;

  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (authenticated && !verified) {
    //return <Navigate to={"/u/" + props.account + '/dashboard'} state={{ from: location }} />;
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;

}


//Private Buiness Side

//Site-Manager
function RequireManager(props) {
  //let auth = useAuth();
  const { authenticated, user, checkAuthentication, signIn3 } = useSanctum();
  console.log(authenticated)
  let location = useLocation();

  let role = user && user.role;
  const verified = role === 'admin' || role === 'site-manager' ? true : false;

  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (authenticated && !verified) {
    //return <Navigate to={"/u/" + props.account + '/dashboard'} state={{ from: location }} />;
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;

}


//Admin
//Make this and encrypted value. 
function RequireAdmin(props) {
  //let auth = useAuth();
  const { authenticated, user, checkAuthentication, signIn3 } = useSanctum();
  //console.log(authenticated && authenticated);
  let location = useLocation();

  let role = user && user.role;

  const verified = role === 'admin' ? true : false;
  //console.log('Role', user.role)

  if (!authenticated && user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (authenticated && !verified && user) {
    //return <Navigate to={"/u/" + props.account + '/dashboard'} state={{ from: location }} />;
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;

}


//GENERAL AUTH / NEEDS TO BE ONLY LOGGED IN 
function RequireAuth(props) {
  //let auth = useAuth();
  const { authenticated, user, checkAuthentication, signIn3 } = useSanctum();
  console.log(authenticated)
  let location = useLocation();

  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;

}

/*
function RequireAdmin() {
  //let auth = useAuth();
  const { authenticated, user, checkAuthentication, signIn3 } = useSanctum();
  console.log(authenticated)
  let location = useLocation();
  if (user.role != 'super-admin') {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/dashboard" state={{ from: location }} />;
  }
  return <Outlet />;
}
*/



function Loader2(props) {
  return (
    <>
      <Menu loadModal3={props.loadModal3} />
      <div
        className='AppBackground2 bg-dark text-light'
        style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        <Spinner animation="grow" />
        <h1>Loading...</h1>
      </div>
    </>
  )
}
function Loader1(props) {
  return (
    <>
      <Menu loadModal3={props.loadModal3} />
      <div className='AppBackgroundClean text-light m-0 p-0' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <div id='loading-screen' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
          <div>
            <div id='loader' ></div>
          </div>
        </div>
      </div>
    </>
  )
}

function Loader(props) {
  return (
    <>
      <Menu loadModal3={props.loadModal3} />

      <div id='loading-screen' className='text-light'>
        <div id='loader' ></div>
      </div>
    </>
  )
}



function MenuFooter(props) {
  return (
    <div className='py-2' style={{ backgroundColor: '#111', marginTop: 'auto', width: '100%' }}>
      <div hidden className='text-muted' style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'row', flexWrap: 'wrap' }}>

        <a className='px-1' target='_blank' title='Twitter' href='#'><TwitterIcon /></a>
        <a className='px-1' target='_blank' title='Instagram' href='https://www.instagram.com/vizcache/'><img src='/img/ig2.png' height='32px' /></a>
        <a className='px-1' target='_blank' title='Opensea.io' href='https://opensea.io/0x949239eFB2baDb46db7683aD58BE18Ba48a02319/'><img src='/img/osea.png' height='32px' /></a>
        <a className='px-1' target='_blank' title='Email' href='mailto:hello@vizcache.com'><AtSignIcon /></a>
      </div>
      <div hidden className='text-muted' style={{ paddingTop: '10px', display: 'flex', justifyContent: 'space-around', flexDirection: 'row', flexWrap: 'wrap' }}>

        <Link className='text-light' to='/about'>About</Link>
        <Link className='text-light' to='/privacy-policy'>Privacy</Link>
        <Link className='text-light' to='/tc'>T&C</Link>
        <Link className='text-light' to='/faq'>FAQ</Link>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <span className='text-muted' style={{ fontSize: '10px' }}> ©MMXXII VizCaché. All Rights Reserved. </span>{' '}
        <span className='text-muted' style={{ fontSize: '10px' }}> Version 0.0.9 </span>
      </div>
    </div>
  )
}

function NotFound(props) {
  return (
    <>
      <div
        className='AppBackgroundClean text-light'
        style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        <h1>404</h1>
        <h4>Lost in a decentralized metaverse...</h4>
        <Link to='/'>Head Home</Link>
      </div>
    </>
  )
}




function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    //window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}










/*
  <Sanctum config={sanctumConfig}>


  //CONNECT WALLET
  const connectWalletHandler = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      console.log("Browser needs Web3 Provider! Please install Metamask.");
      return;
    } else {
      console.log("Wallet Connected!");
    }

    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });

    if (accounts.length !== 0) {
      console.log('Found account', accounts[0])
      //setAccount(accounts[0]);
      setAccount(Web3.utils.toChecksumAddress(accounts[0]));
    } else {
      console.log("Hmm, No Accounts found.");
    }

  }

*/


/*

//CONFIG for Testnest or Mainnets
//TEST
//const explorerBase = 'https:rinkeby.etherscan.io/';
//const marketplaceBase = 'https://testnets.opensea.io/';
//const ipfsGateway = 'https://ipfs.infura.io/ipfs/';
//MAIN
const explorerBase = 'https://mumbai.polygonscan.com/';
const marketplaceBase = 'https://opensea.io/';
const ipfsGateway = 'https://ipfs.infura.io/ipfs/';


*/