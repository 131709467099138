import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ListGroup from 'react-bootstrap/ListGroup';
import {

    Link,

} from "react-router-dom";


import { ChevronLeftIcon } from './Icons'



//UTILITY/////////////////////////////////////////////////////////////////////////////////////////////////
function truncateSimple2(str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + "..." : str;
};

function TruncateSimple(str, n) {

    //const [copied, setCopied] = useState(false);
    let copied = false;

    let string;

    if (str) {
        string = str.replace(str.substring(8, 39), "...");
    } else {
        string = '';
    }

    return (
        <CopyToClipboard text={str}
            onCopy={() => {
                //setCopied(true); 
                console.log('copied');
                alert(' Copied to Clipboard: ' + str);
            }}
        >
            {copied ? (
                <span className='text-success'>{string} {' '}<CopyIcon size={14} /></span>
            ) : (
                <span >{string}  {' '}<CopyIcon size={14} /></span>
            )}
        </CopyToClipboard>

    )
};

function ClickToCopy(str, addr) {

    //const [copied, setCopied] = useState(false);
    let copied = false;

    let string;
    if (str) {
        string = str;
    } else {
        string = '';
    }

    let address;
    if (addr) {
        address = addr;
    } else {
        address = '';
    }

    return (
        <CopyToClipboard text={address}
            onCopy={() => {
                //setCopied(true); 
                console.log('copied');
                alert(' Copied to Clipboard: ' + address);
            }}
        >
            {copied ? (
                <span className='text-success' title={addr} style={{ cursor: 'pointer' }}>{string} {' '}<CopyIcon size={14} /></span>
            ) : (
                <span title={addr} style={{ cursor: 'pointer' }}>{string}  {' '}<CopyIcon size={14} /></span>
            )}
        </CopyToClipboard>

    )
};

const CopyIcon = ({ size = 32, color = "#fff" }) => (<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>);

// 11297108109 palm
function NetworkIcon(props) {
    let size = props.size;
    /*
       <div className='p-1' width={size + 'px'} height={size + 'px'} style={{ aspectRatio:'1', backgroundColor: '#000000aa', borderRadius: '100%', border: 'solid 1px #aaa' }}>
                            <img src='/img/polygon-matic-logo.png' width={size + 'px'} height={size + 'px'} className='p-1'></img>
                        </div>
    */
    if (props.network) {
        return (
            <>
                {
                    props.network === 'ethereum' ? (
                        <>
                            <img src='/img/eth2-logo.png' height={size + 'px'} className='p-1'></img>

                        </>
                    ) : props.network === 'matic' ? (
                        <>
                            <img src='/img/polygon-matic-logo.png' height={size + 'px'} className='p-1'></img>
                        </>
                    ) : props.network === 'ganache' ? (
                        <>
                            <img src='/img/truffle.png' height={size + 'px'} className='p-1'></img>
                        </>
                    ) : props.network === 'palm' ? (
                        <>
                            <img src='/img/palm_logo.svg' height={size + 'px'} className='p-1'></img>
                        </>

                    ) : (
                        <>
                            Unsupported Network
                        </>
                    )
                }
            </>
        )
    } else {
        return (
            <>No Network Detected</>
        )
    }
}

function NetworkIcon2(props) {
    let size = props.size;
    /*
       <div className='p-1' width={size + 'px'} height={size + 'px'} style={{ aspectRatio:'1', backgroundColor: '#000000aa', borderRadius: '100%', border: 'solid 1px #aaa' }}>
                            <img src='/img/polygon-matic-logo.png' width={size + 'px'} height={size + 'px'} className='p-1'></img>
                        </div>
    */
    if (props.network) {
        return (
            <>
                {
                    props.network === 'ethereum' ? (
                        <div className='p-1' width={size + 'px'} height={size + 'px'} style={{ aspectRatio: '1', backgroundColor: '#000000aa', borderRadius: '100%', border: 'solid 1px #aaa' }}>

                            <img src='/img/eth2-logo.png' height={size + 'px'} className='p-1'></img>

                        </div>
                    ) : props.network === 'matic' ? (
                        <div className='p-1' width={size + 'px'} height={size + 'px'} style={{ aspectRatio: '1', backgroundColor: '#000000aa', borderRadius: '100%', border: 'solid 1px #aaa' }}>

                            <img src='/img/polygon-matic-logo.png' height={size + 'px'} className='p-1'></img>
                        </div>
                    ) : props.network === 'ganache' ? (
                        <div className='p-1' width={size + 'px'} height={size + 'px'} style={{ aspectRatio: '1', backgroundColor: '#000000aa', borderRadius: '100%', border: 'solid 1px #aaa' }}>

                            <img src='/img/truffle.png' height={size + 'px'} className='p-1'></img>
                        </div>
                    ) : props.network === 'palm' ? (
                        <div className='p-1' width={size + 'px'} height={size + 'px'} style={{ aspectRatio: '1', backgroundColor: '#000000aa', borderRadius: '100%', border: 'solid 1px #aaa' }}>

                            <img src='/img/palm_logo.svg' height={size + 'px'} className='p-1'></img>
                        </div>

                    ) : (
                        <>
                            Unsupported Network
                        </>
                    )
                }
            </>
        )
    } else {
        return (
            <>No Network Detected</>
        )
    }
}


function NetworkStatus(props) {
    let size = props.size;
    function capitalizeFirstLetter(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);

        }
    }
    return (
        <>
            <div className='mb-4' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {props.currentChainId == props.contractMetadata.network ? (
                    <>
                        <span className='text-success' style={{ fontSize: '14px' }}>
                            <span class="text-success logged-in">●</span> Connected to {capitalizeFirstLetter(props.contractMetadata.type)} Blockchain
                        </span>
                    </>
                ) : (
                    <>
                        <span className='text-danger' style={{ fontSize: '14px' }}>
                            <span class="text-danger logged-in">●</span> Incorrect network! Please switch to {capitalizeFirstLetter(props.contractMetadata.type)}
                        </span>
                    </>)}
                {/*Will Throw Error Until Gallery Contract is on mainnet.*/}
            </div>
        </>
    )
}


function LoaderMarketplace(props) {
    return (
        <>
            <div className='is-blurred AppBackgroundClean text-light m-0 p-0 bg-dark' style={{ minHeight: '100vh' }}>
                <div style={{ backgroundColor: '#111', height: '50vh', display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }} class="caption">
                    <div hidden className='btn-4 p-0 m-3  shadow' style={{ height: '104px', textAlign: 'center', borderRadius: '20px', aspectRatio: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    </div>
                </div>
                <ListGroup hidden className='mt-0 pt-0' variant='flush' horizontal='md' style={{ width: '100%' }}>
                    <ListGroup.Item action className='menuListItem'>Detail</ListGroup.Item>
                    <ListGroup.Item action className='menuListItem'>Trade</ListGroup.Item>
                    <ListGroup.Item action className='menuListItem'>Auction</ListGroup.Item>
                    <ListGroup.Item action className='menuListItem'>Digital Property</ListGroup.Item>
                    <ListGroup.Item action className='menuListItem'>Cache</ListGroup.Item>
                    <ListGroup.Item action className='menuListItem'>Owner Assets</ListGroup.Item>
                </ListGroup>
            </div>
            <div id='loading-screen' className='text-light' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    <h4>Loading from Blockchain</h4>
                    <p>This can take a moment. Please be patient.</p>
                    <div id='loader' ></div>
                </div>
            </div>
        </>
    )
}

function MenuMarketplace(props) {
    return (
        <ListGroup className='mt-0 pt-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id} style={{ height: '74px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Asset Detail</ListGroup.Item>
            {/* <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} className='menuListItem'>Trade</ListGroup.Item>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} className='menuListItem'>Auction</ListGroup.Item>
           
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} className='menuListItem'>Cache</ListGroup.Item>
          
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} className='menuListItem'>Owner Assets</ListGroup.Item>
              */}
        </ListGroup>
    )
}

function MenuMarketplace1155(props) {
    return (
        <ListGroup className='mt-0 pt-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id} style={{ height: '74px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Asset Detail</ListGroup.Item>
            {/* <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} className='menuListItem'>Trade</ListGroup.Item>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} className='menuListItem'>Auction</ListGroup.Item>
           
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} className='menuListItem'>Cache</ListGroup.Item>
          
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} className='menuListItem'>Owner Assets</ListGroup.Item>
              */}
        </ListGroup>
    )
}

function MenuMarketplace1155C(props) {
    return (
        <ListGroup className='mt-0 pt-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id} style={{ height: '74px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Asset Detail</ListGroup.Item>
            {/* <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} className='menuListItem'>Trade</ListGroup.Item>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} className='menuListItem'>Auction</ListGroup.Item>
           
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} className='menuListItem'>Cache</ListGroup.Item>
          
            <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} className='menuListItem'>Owner Assets</ListGroup.Item>
              */}
        </ListGroup>
    )
}

function MenuMarketplaceToken(props) {


    if (props.iOwn) {
        return (
            <ListGroup className='m-0 p-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
                <ListGroup.Item hidden action as={Link} to={'/collections'} className='menuListItem'>Collections</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Contract</ListGroup.Item>
                <ListGroup.Item hidden action as={Link} to={'/' + props.contract} className='menuListItem'>Contract</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Trade</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Auction</ListGroup.Item>
                {/* 
                <ListGroup.Item action active  as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
                */}
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Cache</ListGroup.Item>

                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Owner Assets</ListGroup.Item>
                <div hidden style={{ position: 'fixed', top: '50', left: '50', zIndex: '9999' }}>
                    <Link to={'/' + props.contract} ><ChevronLeftIcon size={64} /></Link>
                </div>
            </ListGroup>
        )
    } else {
        return (
            <ListGroup className='m-0 p-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
                <ListGroup.Item hidden action as={Link} to={'/collections'} className='menuListItem'>Collections</ListGroup.Item>

                <ListGroup.Item action as={Link} to={'/' + props.contract} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Contract</ListGroup.Item>
                <ListGroup.Item hidden action as={Link} to={'/' + props.contract} className='menuListItem'>Contract</ListGroup.Item>



                {props.isTrade ? (
                    <ListGroup.Item action className='bg-success menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/trade'}>
                        <span style={{ fontSize: '18px' }}>Active Trade</span><br />
                    </ListGroup.Item>
                ) : (
                    <>
                    </>
                )
                }

                {props.isAuction ? (
                    <ListGroup.Item action className='bg-warning menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/auction'}>
                        <span style={{ fontSize: '18px' }}>Active Auction</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }

                {props.isLease ? (
                    <ListGroup.Item action className='bg-secondary menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + 'property'}>
                        <span style={{ fontSize: '18px' }}>Active Lease</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }

                {props.isCache ? (
                    <ListGroup.Item action className='bg-info menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/cache'}>
                        <span style={{ fontSize: '18px' }}>Active Cache</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }


                {!props.isTrade && !props.isAuction && !props.isLease && !props.isCache ? (
                    <ListGroup.Item hidden action className='menuListItem' as={Link} to={'/' + props.contract + '/' + props.id}>
                        <span style={{ fontSize: '18px' }}>Request</span><br />
                    </ListGroup.Item>
                ) : (
                    <>
                    </>
                )
                }






                <ListGroup.Item hidden action as={Link} to={'/' + props.contract + '/' + props.id + '/smartframe'} className='menuListItem'>Smartframe</ListGroup.Item>

                {/* <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} className='menuListItem'>Trade</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} className='menuListItem'>Auction</ListGroup.Item>
             
                <ListGroup.Item action active  as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} className='menuListItem'>Cache</ListGroup.Item>
              
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} className='menuListItem'>Owner Assets</ListGroup.Item>  */}
                <div hidden style={{ position: 'fixed', top: '50', left: '50', zIndex: '9999' }}>
                    <Link to={'/' + props.contract} ><ChevronLeftIcon size={64} /></Link>
                </div>
            </ListGroup>
        )
    }

}

function MenuMarketplaceToken1155(props) {

    if (props.iOwn) {
        return (
            <ListGroup className='m-0 p-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
                <ListGroup.Item hidden action as={Link} to={'/collections'} className='menuListItem'>Collections</ListGroup.Item>
                <ListGroup.Item hidden action as={Link} to={'/collections'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Collections</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Contract</ListGroup.Item>

                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/rights'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Rights</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/pool'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Sale Pool</ListGroup.Item>

                <ListGroup.Item hidden action as={Link} to={'/' + props.contract} className='menuListItem'>Contract</ListGroup.Item>
                <ListGroup.Item hidden action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Trade</ListGroup.Item>
                <ListGroup.Item hidden action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Auction</ListGroup.Item>
                {/* 
                <ListGroup.Item action active  as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} className='menuListItem'>Cache</ListGroup.Item>
                */}
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Owner Assets</ListGroup.Item>
                <div hidden style={{ position: 'fixed', top: '50', left: '50', zIndex: '9999' }}>
                    <Link to={'/' + props.contract} ><ChevronLeftIcon size={64} /></Link>
                </div>
            </ListGroup>
        )
    } else {
        return (
            <ListGroup className='m-0 p-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
                <ListGroup.Item hidden action as={Link} to={'/collections'} className='menuListItem'>Collections</ListGroup.Item>

                <ListGroup.Item hidden action as={Link} to={'/collections'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Collections</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Contract</ListGroup.Item>

                <ListGroup.Item hidden action as={Link} to={'/' + props.contract} className='menuListItem'>Contract</ListGroup.Item>

                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/rights'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Rights</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/pool'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Sale Pool</ListGroup.Item>


                {props.isTrade ? (
                    <ListGroup.Item action className='bg-success menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/trade'}>
                        <span style={{ fontSize: '18px' }}>Active Trade</span><br />
                    </ListGroup.Item>
                ) : (
                    <>
                    </>
                )
                }

                {props.isAuction ? (
                    <ListGroup.Item action className='bg-warning menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/auction'}>
                        <span style={{ fontSize: '18px' }}>Active Auction</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }

                {props.isLease ? (
                    <ListGroup.Item action className='bg-secondary menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + 'property'}>
                        <span style={{ fontSize: '18px' }}>Active Lease</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }

                {props.isCache ? (
                    <ListGroup.Item action className='bg-info menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/cache'}>
                        <span style={{ fontSize: '18px' }}>Active Cache</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }


                {!props.isTrade && !props.isAuction && !props.isLease && !props.isCache ? (
                    <ListGroup.Item hidden action className='menuListItem' as={Link} to={'/' + props.contract + '/' + props.id}>
                        <span style={{ fontSize: '18px' }}>Request</span><br />
                    </ListGroup.Item>
                ) : (
                    <>
                    </>
                )
                }






                <ListGroup.Item hidden action as={Link} to={'/' + props.contract + '/' + props.id + '/smartframe'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Smartframe</ListGroup.Item>

                {/* <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} className='menuListItem'>Trade</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} className='menuListItem'>Auction</ListGroup.Item>
             
                <ListGroup.Item action active  as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} className='menuListItem'>Cache</ListGroup.Item>
              
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} className='menuListItem'>Owner Assets</ListGroup.Item>  */}
                <div hidden style={{ position: 'fixed', top: '50', left: '50', zIndex: '9999' }}>
                    <Link to={'/' + props.contract} ><ChevronLeftIcon size={64} /></Link>
                </div>
            </ListGroup>
        )
    }

}

function MenuMarketplaceToken1155C(props) {

    if (props.iOwn) {
        return (
            <ListGroup className='m-0 p-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
                <ListGroup.Item hidden action as={Link} to={'/collections'} className='menuListItem'>Collections</ListGroup.Item>
                <ListGroup.Item hidden action as={Link} to={'/collections'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Collections</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Contract</ListGroup.Item>

                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cause'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Cause</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/pool'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Sale Pool</ListGroup.Item>

                <ListGroup.Item hidden action as={Link} to={'/' + props.contract} className='menuListItem'>Contract</ListGroup.Item>
                <ListGroup.Item hidden action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Trade</ListGroup.Item>
                <ListGroup.Item hidden action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Auction</ListGroup.Item>
                {/* 
                <ListGroup.Item action active  as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} className='menuListItem'>Cache</ListGroup.Item>
                */}
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Owner Assets</ListGroup.Item>
                <div hidden style={{ position: 'fixed', top: '50', left: '50', zIndex: '9999' }}>
                    <Link to={'/' + props.contract} ><ChevronLeftIcon size={64} /></Link>
                </div>
            </ListGroup>
        )
    } else {
        return (
            <ListGroup className='m-0 p-0' variant='flush' horizontal='md' style={{ height: '74px', width: '100%' }}>
                <ListGroup.Item hidden action as={Link} to={'/collections'} className='menuListItem'>Collections</ListGroup.Item>

                <ListGroup.Item hidden action as={Link} to={'/collections'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Collections</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'><ChevronLeftIcon size={24} />Contract</ListGroup.Item>

                <ListGroup.Item hidden action as={Link} to={'/' + props.contract} className='menuListItem'>Contract</ListGroup.Item>

                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cause'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Cause</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/pool'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Sale Pool</ListGroup.Item>


                {props.isTrade ? (
                    <ListGroup.Item action className='bg-success menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/trade'}>
                        <span style={{ fontSize: '18px' }}>Active Trade</span><br />
                    </ListGroup.Item>
                ) : (
                    <>
                    </>
                )
                }

                {props.isAuction ? (
                    <ListGroup.Item action className='bg-warning menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/auction'}>
                        <span style={{ fontSize: '18px' }}>Active Auction</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }

                {props.isLease ? (
                    <ListGroup.Item action className='bg-secondary menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + 'property'}>
                        <span style={{ fontSize: '18px' }}>Active Lease</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }

                {props.isCache ? (
                    <ListGroup.Item action className='bg-info menuListItem' as={Link} to={'/' + props.contract + '/' + props.id + '/cache'}>
                        <span style={{ fontSize: '18px' }}>Active Cache</span><br />
                    </ListGroup.Item>
                ) : (
                    <></>
                )
                }


                {!props.isTrade && !props.isAuction && !props.isLease && !props.isCache ? (
                    <ListGroup.Item hidden action className='menuListItem' as={Link} to={'/' + props.contract + '/' + props.id}>
                        <span style={{ fontSize: '18px' }}>Request</span><br />
                    </ListGroup.Item>
                ) : (
                    <>
                    </>
                )
                }






                <ListGroup.Item hidden action as={Link} to={'/' + props.contract + '/' + props.id + '/smartframe'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='menuListItem'>Smartframe</ListGroup.Item>

                {/* <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/trade'} className='menuListItem'>Trade</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/auction'} className='menuListItem'>Auction</ListGroup.Item>
             
                <ListGroup.Item action active  as={Link} to={'/' + props.contract + '/' + props.id + '/property'} className='menuListItem'>Digital Property</ListGroup.Item>
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/cache'} className='menuListItem'>Cache</ListGroup.Item>
              
                <ListGroup.Item action as={Link} to={'/' + props.contract + '/' + props.id + '/private'} className='menuListItem'>Owner Assets</ListGroup.Item>  */}
                <div hidden style={{ position: 'fixed', top: '50', left: '50', zIndex: '9999' }}>
                    <Link to={'/' + props.contract} ><ChevronLeftIcon size={64} /></Link>
                </div>
            </ListGroup>
        )
    }

}


function LicenseAgreement(props) {
    return (
        <div hidden className='text-dark' style={{ fontSize: '10px' }}>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;</span>This Digital Property Licensing Agreement (the &ldquo;AGREEMENT&rdquo;) is entered into effective this date, [DATE] between [Creator NAME] (&ldquo;CREATOR&rdquo;) and [CLIENT NAME] (&ldquo;CLIENT&rdquo;) with the immutable blockchain transaction on [Contract]<span class="Apple-converted-space">&nbsp;&nbsp;</span>(&ldquo;TRANSACTION&rdquo;). Parties will be referenced and legally bound to blockchain wallet address on the Ethereum network. The Client is assumed to be the current active token owner of the asset listed on the contract at tokenId<span class="Apple-converted-space">&nbsp;&nbsp;</span>All references to the Client in this Agreement shall include Client&rsquo;s parent companies, affiliates, and subsidiaries.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Scope of this Agreement. This Agreement applies to any image, graphics, audio, video objects, scans, documents, texts, concepts, digital assets or any other digital data created or taken by Creator and delivered to the Client (collectively known as &ldquo;DATA&rdquo;). This Agreement governs the relationship between the parties and in no communication or other exchange, shall modify the terms of this Agreement unless agreed to in writing.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Rights: All Data and rights relating to them, including copyright and ownership rights in the media in which the Images are stored, remain the sole and exclusive property of the Creator. This license provides the Client with the limited right to reproduce, publicly display, and distribute the Data only for the agreed upon terms as set forth in the Client Invoice and signed by both parties. Data used for any purpose not directly related outside of those terms must be with the express permission of Creator and may include the payment of additional fees, unless otherwise agreed to in writing. Data may contain copyright management information (CMI) at the discretion of the Creator in the form of either 1) a copyright notice &copy; and/or 2) other copyright and ownership information embedded in the metadata or elsewhere unless otherwise agreed to by the Parties. Removing and/or altering such information is prohibited and constitutes a violation of the Digital Millennium Copyright Act (DMCA) and Client will be responsible to the Creator for any penalties and awards available under that statute.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Relationship of the Parties: The parties agree that Creator is an independent contractor and that neither Creator nor Creator&rsquo;s employees or contract personnel are, or shall be deemed to be, employees of Client. No agency, partnership, joint venture, or employee-employer relationship is intended or created by this Agreement. Neither party is authorized to act as agent or bind the other party except as expressly stated in this Agreement. Creator and the Data or any other deliverables prepared by Creator shall not be deemed a work for hire as defined under Copyright Law. All rights granted to Client are contractual in nature and are expressly defined by this Agreement.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Creation: The manner and method of creating any Data is solely at the discretion of Creator and the Client has no right to control Creator&rsquo;s manner and method of performance under this Agreement.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Delivery: Creator may select delivery of Data in any standard formats at a resolution that Creator determines will be suitable for the Data as licensed. It is the Client&rsquo;s responsibility to verify that the Data is suitable for reproduction. Creator has no obligation to retain or archive any Data delivered to Client.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Fees: All fees, license transfer and expenses payable under this agreement are managed by the smart-contract( address). All sales are final and non-refundable.<span class="Apple-converted-space">&nbsp;</span></p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Cancellation: All purchases and cancellations under this agreement are managed by the smart-contract( address). All sales are final and non-refundable.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>No Exclusivity: This Agreement does not create an exclusive relationship between the parties. Client is free to engage others to perform services of the same or similar nature to those provided by Creator, and Creator shall be entitled to offer and provide services to others, solicit other clients and otherwise advertise the services offered by Creator.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Transfer and Assignment: Client may not assign or transfer this agreement or any rights granted under it. No amendment or waiver of any terms is binding unless in writing and signed by the parties. However, the block transactions may reflect, and Client is bound by authorizations that could not be confirmed in writing because of insufficient time or other practical considerations. Client may list and sell ownership of this license on an acceptable marketplace.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Indemnification: Client will indemnify and defend Creator against all claims, liability, damages, costs, and expenses, including reasonable legal fees and expenses, arising out of the creation or any use of the Data or materials furnished by Client. It is the Client&rsquo;s responsibility to obtain the necessary model or property releases are ensure they are full effect and in force.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>General Law/Arbitration: This Agreement and transaction sets forth the entire understanding of the parties regarding the transaction, and supersedes all prior agreements between the parties. This Agreement shall be governed, interpreted and enforced in accordance with the laws of the State of California. Any claim or litigation arising out of this Agreement or its performance may be commenced only in courts physically located in Unites States of America, California, and the parties hereby consent to the personal jurisdiction of such courts. In the event of any litigation arising out of or relating to this Agreement, the prevailing party shall be entitled to recover its attorneys&rsquo; fees incurred in the litigation. If parties are unable to resolve the dispute, either party may request mediation and/or binding arbitration in a forum mutually agreed to by the parties.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Severability: If one or more of the provisions in the Agreement is found invalid, illegal or unenforceable in any respect, the validity and enforceability of the remaining provisions shall not be affected. Any such provisions will be revised as required to make them enforceable.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Waiver: No action of either party, other than in writing agreed to by the parties, may be construed to waive any provision of this Agreement and a single or partial exercise by either party of any such action will not preclude further exercise of other rights or remedies in this Agreement.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>IN WITNESS WHEREOF, the parties have caused this Digital Property Licensing Agreement to be duly executed as of the mining to this transaction.</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>[Client Name]</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>By: __________________________________ Name: __________________________________</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Title: __________________________________ Date: __________________________________</p>
            <p class="p2"><br /></p>
            <p class="p2"><br /></p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>[Creator Name]</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>By: __________________________________ Name: __________________________________</p>
            <p class="p2"><br /></p>
            <p class="p1"><span class="Apple-converted-space">&nbsp; &nbsp; &nbsp;&nbsp;</span>Date: __________________________________</p>
        </div>
    )
}


export {
    TruncateSimple, NetworkIcon, NetworkIcon2, NetworkStatus, LoaderMarketplace, MenuMarketplace, MenuMarketplace1155, MenuMarketplace1155C, MenuMarketplaceToken, MenuMarketplaceToken1155, MenuMarketplaceToken1155C, ClickToCopy, LicenseAgreement
}